@font-face {
  font-family: 'Destain';
  src: local('Destain'), url(./assets/fonts/Destain/Destain-Xgma.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Plaza';
    src: local('Plaza'), url(./assets/fonts/Plaza/PlazaITC-Art.ttf) format('truetype');
}

.my-nav-sidebar .nav-link.sub-nav-link {
  width: 83.5%;
  margin-left: 2.4rem;
}