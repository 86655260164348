nav.my-custom-navbar {
    height: 58px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

div.user-name-avatar {
    height: 50px;
    padding-left: 0.25rem;
    margin-right: 1rem;
    position: relative;
    cursor: pointer;
}
div.user-name-avatar::after {
    content: "";
    height: 35px;
    position: absolute;
    left: 0;
    top: 42%;
    transform: translateY(-50%);

    border-left: 1px solid #c0c0c0;
}

div.my-dropdown-menu {
    width: 175px;
    border-radius: 4px;
    color: #333333;
    background-color: #ffffff;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);

    position: absolute;
    top: 50px;
    left: 10px;
    z-index: 999;
}

div.my-dropdown-menu button {
    padding: 0.6rem 0 0.6rem 1.15rem;
}
div.my-dropdown-menu button:hover, div.my-dropdown-menu button:active {
    border-radius: 2px;
}
div.my-dropdown-menu button i {
    margin-right: 0.75rem !important;
}
div.my-dropdown-menu .dropdown-divider {
    margin: 0;
}