.dialog-customers.p-dialog {
  min-width: 365px;
  width: 1024px;
  min-height: 20vh;
  max-height: 100vh;
}

.dialog-customers .p-dialog-content {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    box-shadow: inset 0 0 6px rgba(165, 108, 108, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(165, 108, 108, 0.2);
    background-color: #cacaca;
  }
}

.dialog-customers .dialog-row-info {
    padding: 0.25rem 0.25rem 0.5rem;
    align-items: flex-start;
}
.dialog-customers .dialog-content-label {
    font-weight: 600;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.75);
}
.dialog-customers .dialog-content-required {
    font-weight: 600;
    font-size: 0.95rem;
    color: red;
}
.dialog-customers .p-dialog div.p-dialog-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.dialog-customers .p-dialog .p-dialog-content .dialog-last-row .p-dropdown > span.p-dropdown-label {
    font-size: 0.85rem;
}
.dialog-customers .p-dialog div.p-dialog-header {
    height: 3rem;
    line-height: 3rem;
    padding: 0.5rem 1rem;
    background-color: #343A40;
    border-color: #343A40;
    color: #ffffff;
}
.dialog-customers .p-dialog div.p-dialog-footer {
    height: 3rem;
    padding: 0.4rem 1rem;
    /* line-height: 3rem; */
}

.dialog-customers .p-fieldset > legend.p-fieldset-legend {
    width: auto;
    /* height: 40px; */
    padding: 8px 24px 0;
}
.dialog-customers .p-fieldset .p-fieldset-content {
  padding-bottom: 0.5rem;
}
.dialog-customers .p-fieldset#historyFieldset .p-fieldset-content {
  padding-bottom: 1rem;
  max-height: 75.8vh;
  overflow: auto;
}
.dialog-customers .p-fieldset#historyFieldset .p-fieldset-content::-webkit-scrollbar {
  width: 4px;
}
.dialog-customers #historyFieldset .p-timeline {
  margin-top: 10px;
}
.dialog-customers #historyFieldset .p-timeline .p-timeline-event {
  min-height: 64px;
}
.dialog-customers #historyFieldset .p-timeline .p-timeline-event span {
  font-size: 0.85rem;
}
.dialog-customers #historyFieldset .p-timeline .p-timeline-event b {
  font-size: 0.9rem;
}

@media screen and (max-width: 767.5px) {
  .dialog-customers .p-fieldset#historyFieldset {
    margin-top: 0.75rem;
    min-height: fit-content !important;
    padding-bottom: 0 !important;
  }

  .dialog-customers .p-fieldset#historyFieldset .p-fieldset-content {
    max-height: 37rem;
  }
}