.dialog-appointment .appointment-badge {
    text-transform: uppercase;
    font-size: 0.75rem !important;
}
.dialog-appointment .status-pending {
    padding: 0.3rem 0.4rem;
    font-weight: 600;
    color: #007bff;
    background-color: rgb(0, 123, 255, 0.15);
    border-radius: 3px;
}
.dialog-appointment .status-confirmed {
    padding: 0.3rem 0.4rem;
    font-weight: 600;
    color: #28a745;
    background-color: rgb(40, 167, 69, 0.15);
    border-radius: 3px;
}
.dialog-appointment .status-canceled {
    padding: 0.3rem 0.4rem;
    font-weight: 600;
    color: #6c757d;
    background-color: rgb(109, 118, 126, 0.15);
    border-radius: 3px;
}

.dialog-appointment .dialog-row-info {
    margin-bottom: 0.75rem;
    align-items: flex-start;
}
.dialog-appointment .dialog-content-label {
    font-weight: 600;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.75);
}
.dialog-appointment .p-dialog div.p-dialog-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.dialog-appointment .p-dialog .p-dialog-content .dialog-last-row .p-dropdown > span.p-dropdown-label {
    font-size: 0.85rem;
}
.p-dialog div.p-dialog-header {
    height: 3rem;
    line-height: 3rem;
    padding: 0.5rem 1rem;
    background-color: #343A40;
    border-color: #343A40;
    color: #ffffff;
}
.dialog-appointment .p-dialog div.p-dialog-footer {
    height: 3rem;
    padding: 0.4rem 1rem;
    /* line-height: 3rem; */
}

.dialog-appointment .p-fieldset .p-toggleable-content div.p-fieldset-content {
    padding-bottom: 0;
}
.dialog-appointment .p-fieldset > legend.p-fieldset-legend {
    width: auto;
    height: 40px;
}

@media screen and (max-width: 767.5px) {
    .dialog-appointment .p-fieldset#bookingFieldset {
        margin-top: 0.75rem;
        min-height: fit-content !important;
        padding-bottom: 0 !important;
    }
}