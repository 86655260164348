* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Semi Expanded', sans-serif;
}

.wrapper {
  padding: 0;
  margin: 0;
  /* border: 5px solid red; */
}