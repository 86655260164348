.custom-option {
    padding: 1rem 0 0.5rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    /* border: 1px solid springgreen; */
}

.custom-option__calendar {
    margin-right: 1.75rem;
}
.custom-option__calendar div.p-datepicker.p-datepicker-inline {
    font-size: 1.2rem;
    /* padding: 0.5rem 1rem 1rem !important; */
    padding-top: 0.5rem !important;
    padding-bottom: 0.8rem !important;
}

.custom-option__calendar div.p-datepicker.p-datepicker-inline 
.p-datepicker-header .p-datepicker-title {
    line-height: 2.25rem;
}
.custom-option__calendar div.p-datepicker.p-datepicker-inline 
.p-datepicker-header .p-datepicker-title .p-dropdown {
    height: 2.25rem;
}
.custom-option__calendar div.p-datepicker.p-datepicker-inline 
.p-datepicker-header .p-datepicker-title .p-dropdown .p-dropdown-label {
    font-size: 1.15rem;
}

.custom-option__calendar div.p-datepicker.p-datepicker-inline table.p-datepicker-calendar {
    font-size: 1rem !important;
}
.custom-option__calendar div.p-datepicker.p-datepicker-inline table.p-datepicker-calendar th,
.custom-option__calendar div.p-datepicker.p-datepicker-inline table.p-datepicker-calendar td {
    padding: 0.4rem 0.5rem;
    /* border: 1px solid springgreen; */
}
.custom-option__calendar div.p-datepicker.p-datepicker-inline table.p-datepicker-calendar td span {
    width: 38px;
    height: 38px;
    /* border: 1px solid slateblue; */
}

.custom-option__calendar div.p-datepicker.p-datepicker-inline > div.p-datepicker-buttonbar {
    padding-top: 0.4rem;
}
.custom-option__calendar div.p-datepicker.p-datepicker-inline > div.p-datepicker-buttonbar > button > span {
    font-size: 1rem;
}
.custom-option__calendar div.p-datepicker.p-datepicker-inline > div.p-datepicker-buttonbar > button.calendarClearButton {
    display: none;
}