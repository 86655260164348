.head-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 0.2rem 0.5rem;

    /* border: 1px springgreen solid; */
}

.head-wrapper__title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.2rem;
    flex-grow: 1;
}

/* .headWrapper div.p-selectbutton.p-buttonset div.p-button { */
.head-wrapper__select-button div.p-button {
    min-width: 6rem;
    height: 2.2rem;
}