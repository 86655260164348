.time-picker {
    width: 100%;
    display: flex;
    flex-flow: column;
    
    padding: 1rem;
    border: 1px solid #A6A6A6;
    border-radius: 3px;
    position: relative;
}

.time-picker__note {
    font-style: italic;
    color: #848484;
    /* padding: 0.5rem 0 0; */
    display: flex;

    /* border: 1px solid slateblue; */
}
.note__label {
    min-width: 2rem;
    height: 3rem;
    font-weight: 700;
    /* text-decoration: underline; */

    display: flex;
    align-items: center;
}
.note__label i {
    font-size: 1.2rem;
}
.note__selected-date {
    color: #333;
    font-weight: 400;
    font-style: italic;

    background-color: rgba(255, 227, 216, 0.75);
    box-shadow: 2px 2px 5px rgba(100, 100, 100, 0.25);
    /* display: inline-block; */
    padding: 0.25rem 0.6rem;
    margin-left: 0.5rem;
    /* margin-top: -2px; */
}
.note__content {
    line-height: 1.75;
    /* border: 1px solid slateblue; */
}

.time-picker__from-to {
    margin-bottom: 1.5rem;
}

/* .from-to__title {
    margin-bottom: 0.5rem;
} */
/* .from-to__title-selected-date {
    color: #333;
    font-weight: 400;
    font-style: italic;

    background-color: rgba(255, 227, 216, 0.75);
    box-shadow: 2px 2px 5px rgba(100, 100, 100, 0.25);
    padding: 0.3rem 0.6rem;
    margin-left: 0.8rem;
} */

.time-picker__from-to-child {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-left: 1.5rem;
}
.time-picker__from-to-child + .time-picker__from-to-child {
    padding-left: 4rem;
}
.from-to-child__label {
    width: 4.5rem;
}
.from-to__content {
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
}
.from-to__select .p-dropdown {
    /* border: 1px saddlebrown solid; */
    width: 7.5rem;
}
/* .from-to__select .p-dropdown div.p-dropdown-panel ul.p-dropdown-items > li.p-dropdown-item {
    font-size: 1rem !important;
} */

/* .time-picker__slots {

} */
.from-to__label, .slots__label {
    font-weight: 700;
}
.slots__counter {
    margin-left: 0.2rem;
    color: #848484;
    font-style: italic;
}
/* Chưa dùng đến tooltips này */
/* .slots__info {

} */
.slots__hours {
    /* padding-top: 0.5rem; */

    /* display: flex;
    flex-flow: wrap;
    justify-content: space-between; */

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(85px, auto));
    /* grid-template-columns: repeat(auto-fit, minmax(85px, 1fr)); */
    grid-column-gap: 0.6rem;
    /* grid-template-columns: repeat(auto-fill, 85px);
    justify-content: space-between;
    grid-gap: 0.4rem; */

    /* border: teal 1px solid; */
}

/* .slots__hours::after {
    content: '';
    flex: auto; */
        /* width: 100%; */
        /* flex: 0 0 calc(100% - 85px - 85px - 85px - 85px); */
        /* max-width: 480px; */
    /* height: 30px; */
        /* width: calc(100% - 85px - 85px - 85px); */
    /* margin: 0.25rem 0.4rem 0.25rem 0; */
        /* border: 1px solid springgreen; */
/* } */

.button-wrap {
    position: relative;
    /* border: 1px springgreen solid; */
}
.button-wrap__icon {
    position: absolute;
    top: -3px;
    left: 76px;
    /* right: 0; */
    z-index: 1;
    color: #34A835;
    background-color: #fff;
    /* border: 1px solid black; */
    border-radius: 50%;
    height: 1.2rem;
}
.button-wrap__icon--disabled {
    opacity: 0.8;
}

.slots-hour__button {
    /* position: absolute; */
    width: 85px;
    padding: 0.3rem !important;
    margin: 0.3rem 0 !important;
    /* margin: 0.3rem 0.4rem 0.3rem 0 !important; */

    color: #c57347 !important;
    border: 1px solid #c57347 !important;
}
.slots-hour__button:not(.slots-hour__button--disabled):hover {
    color: #333333 !important;
    background-color: rgba(255, 227, 216, 0.75) !important;
    transition: all 0ms !important;
}
.slots-hour__button--active {
    /* font-weight: 600 !important; */
    color: #333333 !important;
    background-color: #FFC09F !important;
    border: 1px solid #FFC09F !important;
}
.slots-hour__button--checked {
    font-weight: 600 !important;
    color: #333333 !important;
    background-color: #FFC09F !important;
    border: 1px solid #FFC09F !important;
}
.slots-hour__button--disabled {
    /* color: #c0c0c0 !important;
    background-color: #eaeaea !important;
    border: 1px solid #c0c0c0 !important; */
    font-weight: 400 !important;
    font-style: italic;
    opacity: 0.7;
    cursor: default !important;
}
/* .slots-hour__button--disabled:hover {
} */

/* .slots-hour__button:last-of-type {
    width: calc(100% - 85px - 85px - 85px - 85px);
    margin-left: auto !important;
    background-color: turquoise !important;
    border: none !important; */
    /* flex: 1; */
/* }
.slots-hour__button:last-of-type:hover {
    background-color: none !important;
    border: none !important;
} */

.time-picker__buttons {
    width: 100%;
    margin-top: 1rem;
    align-self: flex-end;
    justify-self: flex-end;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* position: absolute;
    bottom: 1rem;
    right: 0.5rem; */

    /* border: 1px solid slateblue; */
}
.time-picker__button-cancel {  /*:nth-child(2)*/
    margin-right: 0.6rem !important;
}
.time-picker__button-default {  /*.time-picker__button:first-child*/
    margin-right: auto !important;
}