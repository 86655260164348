.default-option {
    padding: 1rem 0 0.5rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
}
.default-option__list-days {
    margin-right: 1.75rem;
}

.default-option__list-days div.p-listbox {
    width: 14rem;
}
.default-option__list-days div.p-listbox ul.p-listbox-list {
    font-size: 1rem;
}
.default-option__list-days div.p-listbox ul.p-listbox-list li.p-listbox-item {
    padding: 0.5rem 1.25rem;
}
.default-option__list-days div.p-listbox ul.p-listbox-list li.p-listbox-item:hover,
.default-option__list-days div.p-listbox ul.p-listbox-list li.p-listbox-item.p-highlight {
    border-radius: 2px;
}