.login-container {
    width: 100vw;
    height: 100vh;
    background: rgb(197, 197, 197);
    /* background: rgb(177, 216, 155); */
    /* background-image: url(/src/assets/images/login-bg.jpg) no-repeat; */
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;

    /* border: 1px slateblue solid; */
}

.login-container img {
    width: 100vw;
    height: 100vh;
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    opacity: 0.45;
    /* background: linear-gradient(0 rgba()); */
}

.brand-name {
    font-size: 5rem;
    font-weight: 900;
    /* font-stretch: extra-expanded !important; */
    font-family: 'Plaza';
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -25%);
    /* z-index: 1; */
    opacity: 0.8;

    /* border: 1px solid black; */
}

.brand-name__and-symbol {
    font-family: 'Plaza';
    font-size: 3.6rem;
}

.login-container .login-modal {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    /* z-index: 1; */
    opacity: 0.8;
}


@media screen and (max-width: 960px) {
    .brand-name {
        font-size: 4rem;
    }
}
@media screen and (max-width: 768px) {
    .brand-name {
        width: 100%;
        top: 15%;
        text-align: center;
        font-size: 3.5rem;
    }
}