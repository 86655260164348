.customers-table * {
  font-size: 0.85rem !important;
}

.customers-table {
  .table-header {
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    justify-content: space-between;

    .header-title {
      font-size: 1.5rem !important;
      padding-left: 0.2rem;
      flex-grow: 1;
    }
  }

  .btn-checkin {
    border-color: #289e89;
    background-color: #289e89;

    &:hover {
      border-color: #258f7b !important;
      background-color: #258f7b !important;
    }
  }
  .btn-checkout {
    border-color: #fba740;
    background-color: #fba740;

    &:hover {
      border-color: #f0982d !important;
      background-color: #f0982d !important;
    }
  }

  .p-datatable.p-datatable-customers {
    .p-datatable-thead > tr > th {
      text-align: left;
    }
    .p-datatable-tbody > tr > td {
      padding-left: 0;
      padding-right: 0;
      cursor: pointer;

      .p-column-title {
        display: none;
      }
    }
    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }

    .p-paginator {
      padding: 0.3rem 1rem;
      border-radius: 3px;

      .p-paginator-current {
        margin-right: auto;
      }
    }

    .cus-name span {
      font-size: 14px !important;
      font-weight: 600;
    }

    .cus-phone {
      font-size: 13px;
    }

    .cus-email {
      // width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


/* .p-column-filter {} */
.cus-type-badge {
  text-transform: uppercase;
  font-size: 0.75rem !important;
}

.type-diamond {
  padding: 0.3rem 0.4rem;
  font-weight: 600;
  color: #8A5340;
  background-color: #FEEDAF;
  border-radius: 3px;
}
.type-vip {
  padding: 0.3rem 0.4rem;
  font-weight: 600;
  color: #C63737;
  background-color: #FFCDD2;
  border-radius: 3px;
}
.type-regular {
  padding: 0.3rem 0.4rem;
  font-weight: 600;
  // color: #007bff;
  // background-color: rgb(0, 123, 255, 0.15);
  color: #255d8a;
  background-color: #B3E5FC;
  border-radius: 3px;
}
.type-normal {
  padding: 0.3rem 0.4rem;
  font-weight: 600;
  color: #744a91;
  background-color: #ECCFFF;
  // color: #8A5340;
  // background-color: #FEEDAF;
  border-radius: 3px;
}
.type-at-risk {
  padding: 0.3rem 0.4rem;
  font-weight: 600;
  color: #8A5340;
  background-color: #FFD8B2;
  border-radius: 3px;
}
.type-new {
  padding: 0.3rem 0.4rem;
  font-weight: 600;
  // color: #28a745;
  // background-color: rgb(40, 167, 69, 0.15);
  color: #2f7932;
  background-color: #C8E6C9;
  border-radius: 3px;
}

.status-badge {
  text-transform: uppercase;
  font-size: 0.5rem !important;
}
.status-text {
  padding: 5px 8px;
  font-weight: 600;
}
.status-waiting {
  @extend .status-text;
  color: #e69318;
  // background-color: rgb(40, 167, 69, 0.15);
  // color: #255d8a;
  // background-color: #B3E5FC;
  border: 2px solid #e69318;
  border-radius: 50px;
}
.status-in-service {
  @extend .status-text;
  color: #007bff;
  // background-color: rgb(0, 123, 255, 0.15);
  // color: #255d8a;
  // background-color: #B3E5FC;
  border: 2px solid #007bff;
  border-radius: 50px;
}
.status-check-out {
  @extend .status-text;
  color: #6c757d;
  // background-color: rgb(109, 118, 126, 0.15);
  border: 2px solid #6c757d;
  border-radius: 50px;
}


/* @media screen and (max-width: 960px) { */
@media screen and (max-width: 768px) {
  .customers-table .p-datatable.p-datatable-customers .p-datatable-header div.table-header span.header-title {
    font-size: 1.5rem !important;
  }

  .customers-table .p-datatable.p-datatable-customers .p-datatable-thead>tr>th,
  .customers-table .p-datatable.p-datatable-customers .p-datatable-tfoot>tr>td {
    display: none !important;
  }

  .customers-table .p-datatable.p-datatable-customers .p-datatable-tbody>tr {
    border-bottom: 1px solid var(--layer-2);
  }

  .customers-table .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td {
    text-align: left !important;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }

  .customers-table .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td span.cusName {
    font-weight: normal;
  }

  .customers-table .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td span.cusPhone,
  .customers-table .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td span.cusEmail {
    margin-left: 31%;
  }

  .customers-table .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4rem 1rem -.4rem -.4rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 540px) {
  .customers-table .p-datatable.p-datatable-customers .p-datatable-header div.table-header span.header-title {
    font-size: 1.35rem !important;
    display: inline-block;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .p-button.sm-btnRefresh {
    width: 2.05rem;
    padding: 0.5rem;
  }

  .p-button.sm-btnRefresh .p-button-label {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .customers-table .p-datatable.p-datatable-customers .p-datatable-header div.table-header span.header-title {
    font-size: 1.2rem !important;
  }
}

.p-sortable-disabled>input,
.p-sortable-disabled span.p-calendar,
.p-sortable-disabled div.p-multiselect,
.p-sortable-disabled>.p-dropdown {
  /* width: 8.7rem; */
  width: 100%;
}

.p-sortable-disabled span.p-calendar>input {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

/* .p-datepicker .p-component > div.p-datepicker-group-container > div.p-datepicker-group > div.p-datepicker-calendar-container {
    font-size: 0.8rem !important;
} */
.p-datepicker>div.p-datepicker-buttonbar {
  padding: 0.05rem 0 0;
  margin-bottom: -5px;
}

.p-datepicker>div.p-datepicker-buttonbar>button.p-button>span.p-button-label {
  font-size: 0.9rem;
}

.customers-table .technician img.staff-avatar {
  width: 2.25rem;
  height: 2.3rem;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}

.customers-table img.filter-staff-avatar {
  width: 1.95rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}

.customers-table span.cusName {
  font-weight: 600;
}

.customers-table span.cusEmail {
  font-style: italic;
  color: gray;
}