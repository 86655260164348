.datatable-appointments * {
    font-size: 0.85rem !important;
    /* border-radius: 3px; */
}

.datatable-appointments .table-header {
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    justify-content: space-between;
}
.datatable-appointments .table-header .header-title {
    font-size: 1.5rem !important;
    padding-left: 0.2rem;
    flex-grow: 1;
}
/* .datatable-appointments .table-header #btnRefresh {
    padding: 0 !important;
} */

.datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem !important;
}

.datatable-appointments .p-datatable.p-datatable-appointments .p-paginator {
    padding: 0.3rem 1rem;
    border-radius: 3px;
}

.datatable-appointments .p-paginator .p-paginator-current {
    margin-right: auto;
}

.datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-appointments .p-datatable.p-datatable-appointments .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-appointments .p-datatable-appointments .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}
/* .p-column-filter {} */
.appointment-badge {
    text-transform: uppercase;
    font-size: 0.75rem !important;
}
.status-pending {
    padding: 0.3rem 0.4rem;
    font-weight: 600;
    color: #007bff;
    background-color: rgb(0, 123, 255, 0.15);
    border-radius: 3px;
}
.status-confirmed {
    padding: 0.3rem 0.4rem;
    font-weight: 600;
    color: #28a745;
    background-color: rgb(40, 167, 69, 0.15);
    border-radius: 3px;
}
.status-canceled {
    padding: 0.3rem 0.4rem;
    font-weight: 600;
    color: #6c757d;
    background-color: rgb(109, 118, 126, 0.15);
    border-radius: 3px;
}

/* @media screen and (max-width: 960px) { */
@media screen and (max-width: 768px) {
    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-header div.table-header span.header-title {
        font-size: 1.5rem !important;
    }

    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-thead > tr > th,
    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tbody > tr > td {
        text-align: left !important;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }
    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tbody > tr > td span.cusName {
        font-weight: normal;
    }
    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tbody > tr > td span.cusPhone,
    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tbody > tr > td span.cusEmail {
        margin-left: 31%;
    }

    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }
}
@media screen and (max-width: 540px) {
    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-header div.table-header span.header-title {
        font-size: 1.35rem !important;
        display: inline-block;
        width: 100%;
        margin-bottom: 0.5rem;
    }
    
    .p-button.sm-btnRefresh {
        width: 2.05rem;
        padding: 0.5rem;
    }
    .p-button.sm-btnRefresh .p-button-label {
        display: none;
    }
}
@media screen and (max-width: 360px) {
    .datatable-appointments .p-datatable.p-datatable-appointments .p-datatable-header div.table-header span.header-title {
        font-size: 1.2rem !important;
    }
}

.p-sortable-disabled > input,
.p-sortable-disabled span.p-calendar,
.p-sortable-disabled div.p-multiselect,
.p-sortable-disabled > .p-dropdown {
    /* width: 8.7rem; */
    width: 100%;
}
.p-sortable-disabled span.p-calendar > input {
    width: 100%;
    overflow: hidden;
    text-align: center;
}

/* .p-datepicker .p-component > div.p-datepicker-group-container > div.p-datepicker-group > div.p-datepicker-calendar-container {
    font-size: 0.8rem !important;
} */
.p-datepicker > div.p-datepicker-buttonbar {
    padding: 0.05rem 0 0;
    margin-bottom: -5px;
}
.p-datepicker > div.p-datepicker-buttonbar > button.p-button > span.p-button-label {
    font-size: 0.9rem;
}

.datatable-appointments .technician img.staff-avatar {
    width: 2.25rem;
    height: 2.3rem;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
}
.datatable-appointments img.filter-staff-avatar {
    width: 1.95rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
}

.datatable-appointments span.cusName {
    font-weight: 600;
}
.datatable-appointments span.cusEmail {
    font-style: italic;
    color: gray;
}
.datatable-appointments div.cusEmailWrapper {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid springgreen; */
}

.datatable-appointments .p-datatable-appointments .p-datatable-tbody > tr > td div.p-tree {
    /* border: none; */
    padding: 0.15rem 0.1rem 0.15rem 0;
    margin: -8px -12px;
    background: none;
    border: none;
}
.datatable-appointments .p-datatable-appointments .p-datatable-tbody > tr > td div.p-tree 
    > ul.p-tree-container > li.p-treenode,
.datatable-appointments .p-datatable-appointments .p-datatable-tbody > tr > td div.p-tree 
> ul.p-tree-container > li.p-treenode > ul.p-treenode-children li.p-treenode-leaf {
    /* padding-top: -5px !important; */
    padding-top: 0;
    padding-bottom: 0;
}
.datatable-appointments .p-datatable-appointments .p-datatable-tbody > tr > td div.p-tree 
    > ul.p-tree-container > li.p-treenode div.p-treenode-content > span.p-treenode-label {
    margin-left: -5px;
}