.dialog-rewards.p-dialog {
  min-width: 365px;
  width: 1024px;
  min-height: 20vh;
}

.dialog-rewards .dialog-row-info {
    padding: 0.25rem 0.25rem 0.5rem;
    align-items: flex-start;
}
.dialog-rewards .dialog-content-label {
    font-weight: 600;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.75);
}
.dialog-rewards .dialog-content-required {
    font-weight: 600;
    font-size: 0.95rem;
    color: red;
}
.dialog-rewards .p-dialog div.p-dialog-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.dialog-rewards .p-dialog .p-dialog-content .dialog-last-row .p-dropdown > span.p-dropdown-label {
    font-size: 0.85rem;
}
.dialog-rewards .p-dialog div.p-dialog-header {
    height: 3rem;
    line-height: 3rem;
    padding: 0.5rem 1rem;
    background-color: #343A40;
    border-color: #343A40;
    color: #ffffff;
}
.dialog-rewards .p-dialog div.p-dialog-footer {
    height: 3rem;
    padding: 0.4rem 1rem;
    /* line-height: 3rem; */
}

.dialog-rewards .p-fieldset > legend.p-fieldset-legend {
    width: auto;
    height: 40px;
}
.dialog-rewards .p-fieldset .p-fieldset-content {
  padding-bottom: 0.5rem;
}
.dialog-rewards .p-fieldset#rewardFieldset .p-fieldset-content {
  padding-bottom: 1rem;
  max-height: 33rem;
  overflow: auto;
}
.dialog-rewards .p-fieldset#rewardFieldset .p-fieldset-content::-webkit-scrollbar {
  width: 4px;
}
.dialog-rewards #rewardFieldset .p-timeline {
  margin-top: 10px;
}
.dialog-rewards #rewardFieldset .p-timeline .p-timeline-event {
  min-height: 64px;
}
.dialog-rewards #rewardFieldset .p-timeline .p-timeline-event span {
  font-size: 0.85rem;
}
.dialog-rewards #rewardFieldset .p-timeline .p-timeline-event b {
  font-size: 0.9rem;
}

@media screen and (max-width: 767.5px) {
  .dialog-rewards .p-fieldset#rewardFieldset {
    margin-top: 0.75rem;
    min-height: fit-content !important;
    padding-bottom: 0 !important;
  }

  .dialog-rewards .p-fieldset#rewardFieldset .p-fieldset-content {
    max-height: 37rem;
  }
}