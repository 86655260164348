.p-dialog.network-confirm-dialog {
  width: 450px;
}
.p-dialog.network-confirm-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.1rem;
}
.p-dialog.network-confirm-dialog .p-dialog-content {
  align-items: flex-start;
}
.p-dialog.network-confirm-dialog .p-dialog-content i.p-confirm-dialog-icon {
  color: #007ad9;
  margin-top: 10px;
}
.p-dialog.network-confirm-dialog .p-dialog-content .p-confirm-dialog-message {
  font-size: 1rem;
}
.p-dialog.network-confirm-dialog .p-dialog-footer button {
  padding: 0.25rem 0.8rem;
}