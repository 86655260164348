.form-demo .card {
    min-width: 350px;
    padding: 1.5rem;
}
.form-demo .card form {
    margin-top: 2.5rem;
}
.form-demo .card .p-field {
    margin-bottom: 1.75rem;
}
.form-demo .card .p-field span.p-float-label i {
    margin-right: 0.3rem;
}
.form-demo .card .p-field span.p-float-label input.p-inputtext {
    padding-left: 0.6rem;
}
.form-demo .card .p-field span.p-float-label label {
    font-weight: 400;
    margin-left: 0.25rem;
}

.form-demo .card .p-field-checkbox {
    margin-top: -10px;
    margin-bottom: 1.25rem;
}
.form-demo .card .p-field-checkbox label {
    font-size: 0.9rem;
    font-weight: 400;
    font-style: italic;
    margin-left: 0.1rem;
    margin-top: 2px;
}
.form-demo .card .p-field-checkbox .p-checkbox .p-checkbox-box {
    margin-top: -1px;
}

.error-msg {
    color: #d32f2f;
}

/* @media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
} */